import React from "react"
import { graphql, Link } from "gatsby"
import marked from "marked"
import get from "lodash/get"
import { FaCertificate, FaStar, FaMusic } from "react-icons/fa"
import Layout from "../components/layout"
import { getSongPath, getArtistPath } from "../utils/url"
import removeDiacritics from "../utils/removeDiacritics"
import SEO from "../components/seo"

export default ({ data }) => {
  const translation = data.strapiTranslation

  return (
    <Layout>
      <SEO title={translation.title} />
      <article>
        <ol className="breadcrumb">
          <li>
            <Link to="/translations">ΑΡΧΕΙΟ ΜΕΤΑΦΡΑΣΕΩΝ - ΔΙΑΣΚΕΥΩΝ</Link>
          </li>
          <li>{removeDiacritics(translation.title).toUpperCase()}</li>
        </ol>
        <h1 className="my-4 font-semibold font-sans text-gray-900 text-3xl md:text-4xl leading-tight">
          {translation.title}
        </h1>
        <section className="my-2 text-sm">
          {translation.song && (
            <div>
              <span className="mr-1 text-gray-600">Τίτλος πρωτοτύπου:</span>
              <Link
                to={getSongPath(translation.song)}
                className="text-blue-700 hover:underline"
              >
                {translation.song.title}
                {translation.song.pubyear && ` (${translation.song.pubyear})`}
              </Link>
            </div>
          )}
          {translation.translators.length !== 0 && (
            <div>
              <span className="mr-1 text-gray-600">
                {get(translation, ["song", "language"]) === "EL"
                  ? "Διασκευή"
                  : "Μετάφραση"}
                :
              </span>
              <ul className="inline-block comma-separated">
                {translation.translators.map(translator => {
                  return (
                    <li key={translator.id} className="inline-block">
                      <Link
                        to={getArtistPath(translator)}
                        className="text-blue-700 hover:underline"
                      >
                        {translator.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {translation.pubyear && (
            <div>
              <span className="mr-1 text-gray-600">
                Έτος{" "}
                {get(translation, ["song", "language"]) === "EL"
                  ? "διασκευής"
                  : "μετάφρασης"}
                :
              </span>
              <span>{translation.pubyear}</span>
            </div>
          )}
        </section>
        {translation.lyrics && (
          <div
            className="my-6 rtf text-lg md:text-xl text-gray-900 leading-normal font-serif"
            dangerouslySetInnerHTML={{
              __html: marked(translation.lyrics, {
                breaks: true,
                gfm: true,
              }),
            }}
          />
        )}
        {(translation.isOfficialTranslation ||
          translation.isApprovedByCA ||
          translation.isMusicSheetAvailable) && (
          <ul className="uppercase text-xs font-semibold text-gray-900 md:flex md:items-center -mx-1">
            {translation.isOfficialTranslation && (
              <li className="flex items-center rounded mx-1 my-1">
                <FaCertificate size={12} />
                <span className="ml-1">ΕΠΙΣΗΜΗ ΜΕΤΑΦΡΑΣΗ</span>
              </li>
            )}
            {translation.isApprovedByCA && (
              <li className="flex items-center rounded mx-1 my-1">
                <FaStar size={12} />
                <span className="ml-1">ΠΡΟΤΕΙΝΕΤΑΙ ΑΠΟ ΣΕΧΚ</span>
              </li>
            )}
            {translation.isMusicSheetAvailable && (
              <li className="flex items-center rounded mx-1 my-1">
                <FaMusic size={12} />
                <span className="ml-1">ΔΙΑΤΙΘΕΤΑΙ ΠΑΡΤΙΤΟΥΡΑ ΣΤΑ ΕΛΛΗΝΙΚΑ</span>
              </li>
            )}
          </ul>
        )}
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiTranslation(strapiId: { eq: $id }) {
      title
      lyrics: Lyrics
      pubyear
      isApprovedByCA
      isOfficialTranslation
      isMusicSheetAvailable
      translators {
        id
        name
      }
      song {
        id
        title: Title
        language: Language
        pubyear
      }
    }
  }
`
